export default (posts = [], action) => {
    switch (action.type) {
        // depending upon type of action we will define actions in reducers. 
        case 'FETCH_ALL':
            return action.payload;
        case 'LIKE': // mapping the new like depending upon _id
            return posts.map((post) => (post._id === action.payload._id ? action.payload : post));
        case 'CREATE': // adding new post and returning state.
            return [...posts, action.payload];
        case 'UPDATE': // mapping the new update depending upon _id
            return posts.map((post) => (post._id === action.payload._id ? action.payload : post));
        case 'DELETE': // finding the posts and returning state
            return posts.filter((post) => post._id !== action.payload);
        default:
            return posts;
    }
};

    // reducers state always has to be some value.  

